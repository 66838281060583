.preloader-wrapper{
  position: fixed;
  display: none;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  
  &.active {
    display: flex;
  }
  
  .preloader{
  }
}