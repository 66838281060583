@import "/src/assets/styles/colors";
@import "/src/assets/styles/fonts/fonts";

.modal-with-inputs {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  &_type_apikey {
    width: 32rem;
  }

  &__modal-title {
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
  }

  &__loading {
    @include textSetting($color-bg-black, 1.25rem, 600, 1.5rem);
    text-align: center;
    padding: 1rem 0;
  }

  &__modal-button-container {
    display: flex;
    gap: 1.25rem;
    justify-content: end;
    width: fit-content;
    align-self: end;
  }

  &__checkboxes-container {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;
    padding: 0 1.25rem 1.25rem;
  }

  &__label {
    display: flex;
    gap: 1rem;
    width: 214px;
    align-items: center;
    @include textSetting($color-bg-black, 1.25rem, 500, 1.75rem);

    input[type="radio"] {
      display: none;
    }

    .modal-with-inputs__radiomark {
      background-image: url("../../../../assets/media/icons/radio.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      width: 20px;
      height: 20px;
    }

    input[type="radio"]:checked + .modal-with__radiomark {
      background-image: url("../../../../assets/media/icons/radio-active.svg");
    }
  }

  &__copy-message-container,
  &__update-url-container {
    height: 2rem;
    padding-top: 0.625rem;
  }

  &__copy-message,
  &__update-url {
    @include textSetting($color-text-green, 1rem, 500, 1rem);
    text-align: center;
    display: none;
  }

  &__copy-container {
    display: flex;
    width: 100%;
    border: 1px solid $color-input-search-border;
    border-radius: 0.313rem;
  }

  &__copy-input {
    width: 100%;
    border: none;
    padding: 0.5rem;
    border-radius: 0.313rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include textSetting($color-bg-black, 1rem, 500, 1.75rem);

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.successful-refreshed {
  &.isRefreshed {
    display: flex;
  }
}

.successful-refreshed-button {
  &.isRefreshed {
    display: none;
  }
}

.successful-updated {
  &.isOpenApiUpdateSuccess {
    display: flex;
  }
}

.successful-updated {
  &.isLoading {
    display: flex;
  }
}

.updating-message,
.updating-refreshed {
  color: $color-text-black-light;

  &.isLoading {
    display: flex;
  }
}

.successful-updated-button {
  &.isOpenApiUpdateSuccess {
    display: none;
  }
}
