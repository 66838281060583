@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.autocomplete {
  &__root {
    @include textSetting($color-text-input-value, 0.8rem, 500, 1.5rem);
    border-radius: 0.3125rem;
    background: $color-input-bg;
    border: 1px solid $color-input-search-border;
    display: flex;
    gap: 0.3125rem;
    padding-right: 0.3125rem;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;

    &.focused {
      border: 2px solid $color-bg-blue-main;
      outline: $color-bg-blue-main;
    }

    &:focus-visible {
      outline: 0;
    }
  }

  &__input {
    @include textSetting($color-text-input-value, 1rem, 300, 1.5rem);
    background: $color-input-bg;
    border: none;
    border-radius: 0.3125rem;
    padding: 8px 12px;
    outline: 0;
    flex: 1 0 auto;
  }

  &__listbox {
    @include textSetting($color-text-input-value, 1rem, 300, 1.5rem);
    box-sizing: border-box;
    padding: 0.375rem;
    margin: 0.75rem 0;
    min-width: 25rem;
    border-radius: 0.75rem;
    overflow: auto;
    outline: 0;
    max-height: 18.75rem;
    z-index: 100;
    background: $color-input-bg;
    border: 1px solid $color-input-search-border;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
    position: absolute;
    left: 0;
  }

  &__styled-option {
    list-style: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: default;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
    }

    &.Mui-focused,
    &.Mui-focusVisible {
      background-color: $color-default-button-bg-disabled;
      color: $color-text-input-value;
    }

    &[aria-selected="true"].Mui-focused,
    &[aria-selected="true"].Mui-focusVisible {
      background-color: $color-default-button-bg-active;
      color: $color-text-input-value;
    }
  }

  &__popup-indicator {
    outline: 0;
    box-shadow: none;
    border: 0;
    border-radius: 0.25rem;
    background-color: transparent;
    align-self: center !important;
    padding: 0 0.125rem;

    &:hover {
      background-color: $color-input-search-border;
      cursor: pointer;
    }

    & > svg {
      transform: translateY(0.125rem);
    }

    &.popupOpen > svg {
      transform: translateY(0.125rem) rotate(180deg);
    }
  }

  &__clearIndicator {
    outline: 0;
    box-shadow: none;
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    align-self: center !important;
    padding: 0 2px;

    &:hover {
      background-color: $color-input-search-border;
      cursor: pointer;
    }

    & > svg {
      transform: translateY(0.125rem) scale(0.9);
    }
  }

  &__no-options {
    list-style: none;
    padding: 0.5rem;
    cursor: default;
  }

  &__error-container {
    height: 1.25rem;
    margin-top: 0.625rem;
  }

  &__error-message {
    @include textSetting($color-text-red, 1rem, 600, 1rem);
    padding: 0.125rem 0;
    display: none;
  }
}

.input-valid {
  &.false {
    border: 1px solid $color-input-error;
  }
}

.autocomplete-valid-message {
  &.false {
    display: flex;
  }
}
