@use '../../../assets/styles/colors.scss' as *;

.selected {
  display: inline-flex;
  padding: 0.3125rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.3125rem;
  border: 1px solid $color-bg-blue-light;
  background: $color-bg-white-darkest;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  .icon-close {
    cursor: pointer;
  }

  & > p {
    color: $color-text-blue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}