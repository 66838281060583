@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.page-table {
  padding-top: 1.25rem;

  &__loading {
    text-align: center;
  }

  &__title {
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.375rem;
    color: $color-text-input-value;
    padding-bottom: 30px;
  }

  &__header {
    padding: 14px 0;
    border-bottom: solid 0.125rem $color-bg-grey-main;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    margin-bottom: 21px;

    li {
      @include textSetting($color-bg-grey-main, 1.25rem, 500, 2rem);
      list-style: none;
      padding-left: 0.5rem;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__row {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__child-container {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-bg-blue-light;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    scrollbar-width: auto;
    scrollbar-color: $color-bg-blue-light transparent;
    scroll-behavior: smooth;
  }
}

.containerHeight {
  height: calc(100vh - 37rem);

  &.isHigher {
    height: calc(100vh - 30rem);
  }

  &.isFirstFilterActive {
    height: calc(100vh - 34rem);
  }

  &.isSecondFilterActive {
    height: calc(100vh - 37rem);
  }
}
