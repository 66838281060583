@use '../../../assets/styles/colors.scss' as *;

.sign-in-greetings{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 6.25rem;
    background-color: $color-bg-blue-main;
    background-image: url('../../../assets/media/upper-auth-bg.png'), url('../../../assets/media/bottom-auth-bg.png');
    background-position: top right, bottom left;
    background-size: 40%, 102%;
    background-repeat: no-repeat;  

    .logo{
        align-self: start;
    }
    
    .greetings{
        margin-top: 6rem;
        font-size: 4rem;
        color: $color-text-light;
        text-align: center;
    }

    .info{
        margin-top: 3.75rem;
        color: $color-text-light;
        font-size:  1.25rem;
        text-align: center;
        max-width: 70%;
    }

    .noaccaunt{
        margin-top: 12rem;
        color: $color-text-light;
        opacity: 0.4;
        font-size: 20px;
    }
    
    .button {
        margin-top: 1.875rem;
    }
}