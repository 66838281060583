@import "../../assets/styles/colors.scss";

* {
  margin: 0;
  box-sizing: border-box;
  
  &::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-bg-blue-light;
    border-radius: 2px;

  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: auto;
  scrollbar-color: $color-bg-blue-light transparent;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  }
}

html {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

body {
  min-height: 100vh;

  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  flex-grow: 1;
  width: 100%;
}

 textarea{
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  }

h2 {
  font-size: 2.375rem;
  font-weight: 700;
}

