@import "/src/assets/styles/colors.scss";

.header {
  display: flex;
  justify-content: space-between;
  // position: fixed;
  top: 0;
  width: 100%;
  padding: 3.125rem 5.9375rem 3.125rem 9.75rem;
  z-index: 20;

  .header-info-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .header-info-section__handlers{
      display: flex;

    }
    .lang-select {
      display: flex;
      align-items: center;
      p {
      margin-right: 0.5rem;
    }
      margin-right: 0.7rem;
    }

    &__handlers {
      padding-right: 2.625rem;

      .handler-icon-theme {
        padding-right: 0.9375rem;
        cursor: pointer;
      }

      .handler-icon-notification {
        cursor: pointer;
      }
    }
  }

  .header-user-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__info {
      padding-right: 1rem;

      .user-name {
        font-size: 1.5rem;
      }

      .user-email {
        font-size: 0.75rem;
        color: $color-text-grey-dark;
      }
    }

    &__settings {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      position: relative;

      .settings-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.arrow {
  border: solid $color-input-border;
  background-color: transparent;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  --webkit-transform: rotate(45deg);
}
