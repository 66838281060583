@use '../../../assets/styles/colors.scss' as *;

.dashboard-card {
  flex-grow: 1;
  max-width: 27.75rem;
  padding:  1.25rem 1.875rem;
  border-radius: 1.25rem;
  background-color: $color-input-bg;
  color:$color-bg-blue-main;

  &__value {
    text-align: end;
    font-size: 96px;
    font-weight: 700;
  }

  &.blue {
    background-color: $color-bg-blue-main;
    color: $color-input-bg;
  }
}