.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: auto;
 
  .custom-toast-container{
    .Toastify__toast {
        background-color: #66CC82 !important; 
        color: #fff !important; 
        border-radius: 6px;
      }
      .Toastify__toast--error {
        background-color: red !important; 
      }
    }
}
