@use '../../../assets/styles/colors.scss' as *;

.common-table-row {
  display: grid;
  gap: 0.5rem;
  padding: 1.25rem 0.825rem 0.625rem;
  border-bottom: 1px solid $color-bg-grey-light;

  .common-table-cell{
    color: $color-text-blue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;

    &.black {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.75rem;
      color: $color-text-input-value;
    }

    &.justifyCenter {
      text-align: center;
    }
  }
}