@import "../../assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dropdown-menu-container {
  position: relative;
}

.menu-dropdown,
.sub-menu-dropdown {
  margin-top: 0.5rem !important;
  display: inline-flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0 0 0.625rem 0.625rem;
  background: $color-bg-white-darker;
  box-shadow: 0 0.25rem 0.25rem 0 $color-bg-blue-black-shadow;
  width: fit-content;
  border: 1px solid $color-input-search-border;
  max-height: 33.3325rem;
  overflow-y: scroll;

  & > ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0.65rem;
    @include textSetting($color-text-input-value, 1rem, 500, 1.5rem);
  }

  .dropdown-item {
    list-style: none;

    &:hover {
      cursor: pointer;
      color: $color-text-blue;
    }
  }
}

.sub-menu-dropdown {
  position: absolute;
  max-width: 12rem;
  z-index: 10;
  gap: 0.8125rem;
}

.sub-menu-dropdown-pools {
  top: 35px !important;
  left: 120px !important;
}

.sub-menu-dropdown-status {
  top: 70px !important;
  left: 120px !important;
}
