.common-popup {
  position: absolute;
  z-index: 10;
  bottom: 3.5rem;
  left: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  border: 1px solid black;
  background-color: aliceblue;
  transition: all ease-in-out 0.15s;
}