.users {
  &__waiting-title {
    text-align: center;
    width: 100%;
  }

  .search-container {
    width: 80%;
  }
}
