.sign-in-form {
  width: 50%;
  padding: 14.75rem 7.75rem 1rem;

  & > h2 {
    margin-bottom: 2.5rem;
  }

  .button {
    width: 100%;
    margin-top: 7.5rem;
  }
}
