@mixin textSetting($color, $fontSize, $fontWeigh, $lineHeight) {
  color: $color;
  font-size: $fontSize;
  font-style: normal;
  font-weight: $fontWeigh;
  line-height: $lineHeight;
}

@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("./Raleway/Raleway-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
