@import "/src/assets/styles/colors";
@import "/src/assets/styles/fonts/fonts";

.modal-with-inputs {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  &__modal-title {
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
  }

  &__modal-button-container {
    display: flex;
    gap: 1.25rem;
    justify-content: end;
    width: fit-content;
    align-self: end;
  }

  &__checkboxes-container {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;
    padding: 0 1.25rem 1.25rem;
  }

  &__label {
    display: flex;
    gap: 1rem;
    width: 214px;
    align-items: center;
    @include textSetting($color-bg-black, 1.25rem, 500, 1.75rem);

    input[type="radio"] {
      display: none;
    }

    .modal-with-inputs__radiomark {
      background-image: url("../../../../assets/media/icons/radio.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      width: 20px;
      height: 20px;
    }

    input[type="radio"]:checked + .modal-with-inputs__radiomark {
      background-image: url("../../../../assets/media/icons/radio-active.svg");
    }
  }
}
