@import "/src/assets/styles/colors";
@import "/src/assets/styles/fonts/fonts";

.modal-content {
  padding-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  &__loading {
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  button {
    align-self: end;
  }

  &__modal-title {
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
  }

  &__modal-subtitle {
    @include textSetting($color-bg-black, 1rem, 300, 1.7653rem);
  }

  &__title {
    @include textSetting($color-text-grey, 1rem, 600, 1.75rem);
  }

  &__subtitle {
    @include textSetting($color-text-input-value, 1.375rem, 500, 2rem);
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__checkboxes-container {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;
    padding: 0.625rem 1.25rem;
  }

  &__label {
    display: flex;
    gap: 1rem;
    width: 214px;
    align-items: center;
    @include textSetting($color-bg-black, 1.25rem, 500, 1.75rem);

    input[type="radio"] {
      display: none;
    }

    .modal-content__radiomark {
      background-image: url("../../../../assets/media/icons/radio.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      width: 20px;
      height: 20px;
    }

    input[type="radio"]:checked + .modal-content__radiomark {
      background-image: url("../../../../assets/media/icons/radio-active.svg");
    }
  }
}

.modal-title {
  &.active {
    color: $color-text-active;
  }

  &.inactive {
    color: $color-text-nonactive;
  }

  &.blocked {
    color: $color-text-blocked;
  }

  &.success {
    color: $color-text-green;
    display: flex;
    gap: 0.6875rem;
    align-items: center;
  }
}
