@use '../../../assets/styles/colors.scss' as *;

.bind-checkbox-wrapper{
  width: 100%;
  height: 100%;

  .bind-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;    
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;

    &:hover{
      background-image: url("../../../assets/media/icons/bind-checkbox.svg");
    }

    &.active {
      background-image: url("../../../assets/media/icons/bind-checkbox.svg");

      &:hover {
        background-image: url("../../../assets/images/unbindIcon.svg");
      }
    }
    
  }

}