.feedback-btn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover, &.active {
    filter: brightness(0) saturate(100%) invert(50%) sepia(69%) saturate(3249%) hue-rotate(206deg) brightness(100%) contrast(102%);
    
  }
}