@use '../../../assets/styles/colors.scss' as *;

.binding-table-pool-info {

  position: relative;
  margin-bottom: 2rem;
  background-color: white;
  z-index: 9;

  .pool-info-row{
    align-items: end;
    display: flex;

    h3 {
      margin: 0 1rem 0.3rem 0;
      opacity: 0.4;
    }
    h2 {
      color: $color-text-blue;
    }
  }
}
.binding-table-scroll-container {
  max-height: 500px;
  padding: 4rem;
}

.binding-table-wrapper{

  position: relative;
  padding: 1rem 1rem 1rem 0;
  margin-top: -5rem;
  max-width: 100%;
  overflow: auto;
  max-height: 450px;

  
  .input-cell{
    grid-row: 1;
    grid-column: 1;
 }

 .binding-table__cell.triangle-cell{
  border: 3px solid $color-input-border-light;
  border-bottom-width: 1px;
 }

  .binding {
    &-table{
    display: grid;
    border-radius: 4px;
    
    &__cell{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border: 1px solid $color-bg-grey-light;

      &:last-child {
        border-bottom-right-radius: 6px;
        overflow: hidden;
      }
      
      span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        text-transform: uppercase;
      }
    
      &.title-cell {
      padding: 1rem 1.5rem;
      border-color: $color-input-border-light;
      background-color: $color-input-bg;
      color: $color-text-blue;

      &.column-cell {
        height: 4.5rem;
        position: relative;
        cursor: pointer;
        border-top-width: 3px;

        &:hover .common-popup {
        opacity: 1;
        }
        
      }

      &.row-cell {
        border-left-width: 3px;
        width: 17.5rem;
      }
      }
    }
    }
  }
}
