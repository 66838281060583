@import "/src/assets/styles/colors.scss";

.bullet {
  width: 15px;
  height: 15px;
  background-color: $color-text-grey-dark;
  border-radius: 50%;
}

.bullet-active {
  width: 15px;
  height: 15px;
  background-color: $color-bg-blue-main;
  border-radius: 50%;
}
