
.logo {
	display: flex;
	align-items: center;
	cursor: pointer;

	& > i {
		display: block;
		width: 2.8125rem;
		height: 3.375rem;
		background-image: url('../../assets/media/icons/auth-logo.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}
}
