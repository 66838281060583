@use '../../../assets/styles/colors.scss' as *;

.feedback {

  .input{
    padding: 0.5rem;
    border-radius: 4px;
  }
  &-title {
    color: $color-text-blue;
    margin-bottom: 2rem;
  }

  &-input {
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    resize: none;
    border: 1px solid $color-text-blue;
    border-radius: 4px;
    outline: none;

  }

  
  .feedback-checkbox{
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin-left: -0.7rem;
    margin-bottom: 0.7rem;
  }

  &-btns {
    display: flex;
    gap: 0.2rem;
    margin-left: -0.7rem;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0.7rem 0;
  }
}