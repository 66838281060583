@use '../../../../assets/styles/colors.scss' as *;

.common-table-header-title{
  display: flex;
  color: $color-text-grey;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  list-style: none;

  &.justifyCenter {
    justify-content: center;
  }
}