@use '../../assets/styles/colors.scss' as *;

.binding{
  h1 {
    margin-bottom: 1rem;
  }

  .search-wrapper{
    display: flex;
    gap: 2rem;
    width: 100%;
    .binding-dropdown {
      width: 40%;
    }
  }
}

