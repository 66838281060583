@import "/src/assets/styles/colors.scss";

.service-register-section {
  padding: 8.125rem 9.375rem 16.5rem 10.5rem;
  display: flex;
  flex-direction: row;
  gap: 12.25rem;
  box-sizing: border-box;

  .button {
    width: 80%;
  }

  .service-section {
    display: flex;
    flex-direction: column-reverse;

    &__header {
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.925rem;
      padding-top: 1rem;
    }

    &__text {
      font-weight: 600;
      font-size: 1rem;
      color: $color-br-gray-light;
    }
  }

  .register-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    margin-right: 2.25rem;
    gap: 0.125rem;

    &__text {
      font-size: 1.25rem;
      padding-top: 4.625rem;
      padding-bottom: 3.125rem;
      font-weight: 400;
    }
  }

  @media (max-width: 1800px) {
    gap: 3rem;
    justify-content: space-between;

    .button {
      width: 354px;
    }
  }  
}

.preview-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 9.375rem 1rem 20.125rem 1rem;
  
    &__image {
      width: 100%;
    }
  
}


@media (max-width: 1200px) {
  .service-register-section {
    padding: 2.5rem 2.5rem;
    flex-direction: column;
    justify-content: center;

    .service-section {
      align-items: center;
  
      &__header {
        text-align: center;
      }
    }
  
    .register-section {
      padding: 0 10rem;
      align-items: center;
      margin: 0;
      
      &__text {
        text-align: center;
      }
    }

  }

  @media (max-width: 700px) {
    .service-section{

      .service-section__header {
        font-size: 3.5rem;
        line-height: 4.5rem;
      }
    }

    .service-register-section {
      padding: 0;
      .register-section {
        &__text {
          padding-top: 1.625rem;
          padding-bottom: 2.125rem;
  
        }
      }
    }

    .preview-section {
      padding: 5rem 1rem;
    }
    
  }

  @media (max-width: 560px) {
    .service-section{

      .service-section__header {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
    }

    .service-register-section {
      gap: 1rem;
      .register-section {
        padding: 0 2rem;
      }
    }
    
  }

}
