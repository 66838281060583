@import "/src/assets/styles/colors";

.MuiModal-backdrop {
  background-color: $color-bg-grey-dark;
}

.modal {
  &__container {
    background-color: $color-bg-main;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal-size {
  &.medium {
    padding: 2.5rem 2.5rem 1.25rem 2.5rem;
  }

  &.small {
    padding: 1.25rem;
  }
}
