@import "/src/assets/styles/colors.scss";

.target-users {
  display: flex;
  flex-direction: row;
  padding: 20.625rem 2.5rem 22.25rem 3.5rem;
  justify-content: center;
  gap: 6.75rem;
  z-index: 1;

  .target-users-info {
    display: flex;
    background-color: $color-bg-white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    &__header {
      background-color: transparent;
      font-size: 4rem;
      color: $color-text-input-value;
      font-weight: 700;
    }

    &__bullets {
      position: absolute;
      bottom: 7rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }

}

.target-users-cards {
  position: relative;
  transition: 0.5s ease-in-out;
  z-index: 2;
  background-color: transparent;
  width: 60%;
}

.target-users-cards__item {
  background-color: $color-bg-white;
  width: 29rem;
  height: 550px;
  border-radius: 1rem;
  padding: 2rem 1.625rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

  &__label {
    color: $color-text-input-value;
    font-size: 1.875rem;
    font-weight: 700;
    transition: 0.5s ease-in-out;
  }

  &__description {
    color: $color-text-grey-dark;
    font-size: 1rem;
    font-weight: 300;
    transition: 0.5s ease-in-out;
  }
}

.target-users-cards__active-card {
  > :first-child {
    background-color: $color-bg-grey-medium;
    transition: 0.5s ease-in-out;

    .target-users-cards__item__label,
    .target-users-cards__item__description {
      color: $color-bg-white;
      transition: 0.5s ease-in-out;
    }
  }
}

//Swiper Styles
.swiper {
  position: static !important;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.swiper-pagination {
  text-align: start !important;
  position: absolute !important;
  left: -52% !important;
  top: 75% !important;
  display: none;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important ;
}

@media (max-width: 1800px) {
  .target-users{
    gap: 1rem;
  }
}
@media (max-width: 1700px) {
  .target-users-cards__item{
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .target-users{
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2.5rem 12rem 2.5rem;

    .target-users-info__bullets{
      top: calc(620px + 100%);
      left: 50%;
      transform: translateX(-50%);
    }
    
    .target-users-cards{
      width: 88%;
      
      .swiper {
        justify-content: center;
      }

      .container {
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@media (max-width: 900px) {
  .target-users{
    .target-users-cards{
      width: 100%;
    }
  }
}

@media (max-width: 761px) {
  .target-users{
    padding: 3rem  2.5rem 6rem;
    .target-users-info__header{
      font-size: 3.5rem;
    }
    .target-users-info__bullets{
      display: none;
     }

    .target-users-cards{
     .container {
      top: 105%;
      transform: translateX(-50%);
    }
  }
  }

  .target-users-cards__item{
    max-width: 400px;
  }

  .swiper-backface-hidden {
    .swiper-slide{
      display: flex;
      justify-content: center;
    }
  }
 
}

@media (max-width: 500px) {
  .target-users{
    .target-users-info__header{
      font-size: 3rem;
    }

    .target-users-cards__item__label{
      font-size: 1.6rem;
    }
  }
}
