@import "/src/assets/styles/colors.scss";

.features {
  display: flex;
  padding: 6.25rem 9.1875rem 3.375rem 9.1875rem;
  background-color: $color-bg-white-darker;
  flex-direction: column;

  .features-header {
    background-color: transparent;
    padding-bottom: 4.625rem;
    font-size: 4rem;
    color: $color-text-input-value;
    font-weight: 700;
  }

  .features-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
    gap: 1.25rem;
    background-color: transparent;
    transition: 0.5s ease-in-out;

    .features-item {
      grid-column: span 4;
      position: relative;
      background-color: $color-bg-white;
      border-radius: 0.625rem;
      padding: 1.5625rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      transition: 0.5s ease-in-out;

      &:hover {
        background-color: $color-bg-blue-main;
        color: $color-bg-white;

        .features-item__label {
          color: $color-bg-white;
        }

        .features-item__description {
          color: $color-bg-white;
        }
      }
    }

    .features-item:nth-child(1) {
      grid-column: span 5;
      height: 20.625rem;
    }

    .features-item:nth-child(2) {
      grid-column: span 4;
      height: 20.625rem;
    }

    .features-item:nth-child(3) {
      grid-row: span 3;
      grid-column: span 3;
      margin-bottom: 1.25rem;
      width: 115%;
    }

    .features-item:nth-child(5) {
      grid-column: span 5;
      height: 20.625rem;
    }

    .features-item__label {
      color: $color-text-input-value;
      font-size: 1.875rem;
      font-weight: 700;
      transition: 0.5s ease-in-out;
    }

    .features-item__description {
      color: $color-text-grey-dark;
      z-index: 1;
      font-size: 17px;
      line-height: 24px;
      transition: 0.5s ease-in-out;
    }
  }

  @media (max-width: 1470px) {
    .features-container {
      .features-item{
        .features-item__label {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: 1350px) {
    padding: 3rem 3.5rem;
  
  .features-container {
    .features-item {
      grid-column: span 6 ;
      height: 15.625rem ;
    }
    .features-item:nth-child(1) {
      grid-column: span 6 ;
      height: 15.625rem ;
    }
  
    .features-item:nth-child(2) {
      grid-column: span 6 ;
      height: 15.625rem ;
    }
  
    .features-item:nth-child(3) {
      grid-column: span 6 ;
      width: 100% ;
      margin-bottom: 0 ;
      height: 15.625rem ;
      grid-row: span 1 ;
    }
  
    .features-item:nth-child(5) {
      grid-column: span 12 ;
      height: 15.625rem ;
    }
   }
}

  @media (max-width: 786px) {
    .features-container {
      .features-item{
        &:nth-child(1n) {
          min-height: fit-content;
          height: 100%;
        }
        .features-item__label {
          font-size: 1.4rem;
          margin-bottom: 10px;
        }
        .features-item__description{
          font-size: 16px;
          line-height: 20px;
        
        }
      }
    }
  } 

  @media (max-width: 650px) {
    .features-container {
      display: flex;
      flex-direction: column;
    }
    .features-header {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
  } 
  @media (max-width: 450px) {
   
    .features-header {
      font-size: 2.9rem;
      line-height: 3.5rem;
    }
  } 
}


