@import "/src/assets/styles/colors.scss";

.container {
  position: absolute;
  bottom: 0;
  left: -67%;
  display: flex;
  flex-direction: row;
  gap: 1.625rem;
  z-index: 10;
}
.container-btn {
  border: 2px solid $color-bg-blue-main;
  border-radius: 8px;
  background-color: $color-bg-blue-main;
  padding: 1rem 3.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}

.container-btn:hover {
  background-color: $color-bg-white;

  .btn-arrow-left,
  .btn-arrow-right {
    border-top: 2px solid $color-bg-blue-main;
    border-left: 2px solid $color-bg-blue-main;
    background-color: $color-bg-white;
  }

  .btn-arrow-left::after,
  .btn-arrow-right::after {
    background-color: $color-bg-blue-main;
  }
}

.container-btn:nth-child(1) {
  padding-left: 20px;
}

.container-btn:nth-child(2) {
  padding-right: 20px;
}

.btn-arrow-left,
.btn-arrow-right {
  background-color: $color-bg-blue-main;
  width: 10px;
  height: 10px;
  border-top: 2px solid $color-bg-white;
  border-left: 2px solid $color-bg-white;
  transition: 0.25s ease-in-out;
  transform: rotate(-45deg);
}

.btn-arrow-right {
  transform: rotate(135deg);
}

.btn-arrow-left::after,
.btn-arrow-right::after {
  content: "";
  display: block;
  width: 2px;
  height: 50px;
  background-color: rgb(255, 255, 255);
  transform: rotate(-45deg) translate(17px, 6px);
  left: 0;
  top: 0;
  transition: 0.25s ease-in-out;
}
