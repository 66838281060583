@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.modalInput {
  height: 40px;
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid $color-input-search-border;
  background: $color-input-bg;
  color: $color-text-input-value;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.7653rem;
  padding: 0 1.25rem;

  &::placeholder {
    color: $color-input-placeholder-grey;
  }

  &:focus {
    border: 2px solid $color-input-border;
    outline: $color-input-border;
  }

  &_type_file {
    height: 65px;
    width: 100%;
    border-radius: 0.425rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-text-input-value;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &__label {
    @include textSetting($color-text-blue-light, 1rem, 600, 1.75rem);
  }

  &__error-container {
    height: 1rem;
  }

  &__error-message {
    @include textSetting($color-text-red, 1rem, 600, 1rem);
    display: none;
  }
}

input[type="file"] {
  display: none;
}

.input-valid {
  &.false {
    border: 1px solid $color-input-error;
  }
}

.input-valid-message {
  &.false {
    display: flex;
  }
}

.isLabelExist {
  height: 4.0625rem;
  border-radius: 0.625rem;
}
