@import "/src/assets/styles/colors.scss";

.navbar {
  display: flex;
  flex-direction: column;
  padding-left: 8rem;
  padding-right: 2rem;
  gap: 2rem;

  .navbar-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    &__icon {
      width: 22px;
    }

    &__text {
      font-size: 1.25rem;

      &__link {
        position: relative;
        text-decoration: none;
        color: $color-text-grey;
        transition: all 0.25s;
        white-space: nowrap;

        &.active {
          color: $color-text-input-value;

          .navbar-item__text__link__tag {
            border: 0.125rem solid $color-text-blue;
            background-color: $color-text-blue;
          }
        }

        &__tag {
          position: absolute;
          left: -3.75rem;
          top: 0.1875rem;
          height: 1.375rem;
          border: 0.125rem solid transparent;
          border-radius: 1rem;
          background-color: transparent;
        }
      }
    }
  }
}
