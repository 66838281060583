@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";
@import "/src/assets/styles/colors.scss";

.status-label-container {
  position: relative;
  width: 100%;
}

.status-label {
  width: 100%;
  text-align: center;
  padding: 0.313rem 1.25rem;
  border-radius: 0.313rem;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
  transition: opacity 0.2s linear;

  &__tooltip-container {
    width: 100%;
  }

  &.active {
    background-color: $color-label-active;
    color: $color-text-active;
  }

  &.inactive {
    background-color: $color-label-inactive;
    color: $color-text-nonactive;
  }

  &.blocked {
    background-color: $color-label-error;
    color: $color-input-error;
  }

  &:hover {
    opacity: 0.85;
  }
}

.page-table-field {
  padding: 1.25rem 0.825rem 0.625rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  border-bottom: 1px solid $color-bg-grey-light;

  li {
    list-style: none;
    padding-left: 0.5rem;

    &:first-child {
      padding-left: 0;
    }
  }

  &__username {
    color: $color-text-input-value;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
  }

  &__email {
    color: $color-text-nonactive;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }

  &__name {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    color: $color-text-input-value;
  }

  &__quantity {
    @include textSetting($color-text-blue, 1.5rem, 600, 2rem);
  }

  &__status {
    display: flex;
    gap: 1.25rem;
    align-items: center;
  }

  &__button {
    background-color: transparent;
    outline: transparent;
    border: none;
    cursor: pointer;
  }

  &__empty-container {
    width: 3.15rem;
  }
}
