@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.tooltip {
  &__menu {
    background-color: $color-bg-main;
    padding: 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0.25rem 0.25rem 0 $color-bg-blue-black;
    display: flex;
    width: fit-content;
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 10;
  }

  &__menu-item {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
  }

  .status-modal {
    fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 0.9375rem;
      padding: 0;
    }

    &__title {
      @include textSetting($color-text-grey-dark, 1.25rem, 600, 2rem);
      padding-bottom: 0.625rem;
    }

    &__label {
      display: flex;
      gap: 1rem;
      width: 214px;
      align-items: center;

      input[type="radio"] {
        display: none;
      }

      .status-modal__radiomark {
        background-image: url("../../assets/media/icons/radio.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        width: 20px;
        height: 20px;
      }

      input[type="radio"]:checked + .status-modal__radiomark {
        background-image: url("../../assets/media/icons/radio-active.svg");
      }
    }

    &__button-container {
      padding-top: 1rem;
      display: flex;
      gap: 1.25rem;
    }
  }
}
