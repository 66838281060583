@use '../../../assets/styles/colors.scss' as *;

.sign-up-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100vh;
    padding: 0 7.75rem ;
    background-color: $color-bg-main;

    & > h2 {
        margin-bottom: 2.5rem;
    }

    .button{
        width: 100%;
        margin-top: 7.5rem;
    }
}