/* text */
$color-text-light: #fdfdfd;
$color-text-dark-light: #eef2ff;
$color-text-dark: #646668;
$color-text-active: #29a54b;
$color-text-nonactive: #a4a4c7;
$color-text-input-value: #222224;
$color-text-black-light: #262628;
$color-text-blue-light: #87a8fe;
$color-text-blue: #5282ff;
$color-text-blue-light: #87a8fe;
$color-text-red: #DF251F;
$color-text-blocked: #df251f;
$color-text-grey: #bdbdd2;
$color-text-grey-dark: #a3a3ad;
$color-text-green: #66cc82;

/* common */
$color-bg-main: #fdfdfd;
$color-bg-blue-main: #5282ff;
$color-bg-blue-light: #769cff;
$color-bg-blue-dark: #366eff;
$color-bg-blue-light: #769cff;
$color-bg-white-darkest: #dfe7fd;
$color-bg-blue-black: #5282ff4c;
$color-bg-blue-black-shadow: #5282ff38;
$color-bg-grey-light: #dee1ea;
$color-bg-white: #fff;
$color-bg-white-darker: #eff3f8;
$color-bg-white-darkest: #dfe7fd;
$color-br-gray-light: #c4c4c4;
$color-bg-grey-main: #bdbdd2;
$color-bg-grey-medium: #5e5e60;
$color-bg-grey-dark: #22222460;
$color-bg-black: #000;

/* inputs */
$color-input-error: #e84f4f;
$color-input-bg: #f2f4fb;
$color-input-border: #5282ff;
$color-input-search-border: #779dff75;
$color-input-placeholder: #bdbdd2;
$color-input-placeholder-grey: #a3a3ad;
$color-input-border-light: #d2defe;

/* buttons, labels */
$color-label-active: #c4ebce;
$color-label-error: #f6b9b9;
$color-label-inactive: #e9ebf1;
$color-default-button-bg: #5282ff;
$color-default-button-bg-active: #4478ff;
$color-default-button-bg-disabled: #dce6ff;
$color-default-button-bg-light-blue: #dce6ff;
$color-default-button-bg-extra-light-blue: #e1e9fd;
$color-default-button-bg-white: #fdfdfd;
$color-default-button-shadow: #2d60e3a5;
$color-bg-label-green-light: #4fe3c1;
$color-bg-label-green-light-with-opacity: rgba(79, 227, 193, 0.05);
$color-bg-label-green: #48cc90;
$color-bg-label-green-with-opacity: rgba(72, 204, 144, 0.05);
$color-bg-label-dark-green: #3ca404;
$color-bg-label-dark-green-disabled: rgba(60, 164, 4, 0.3);
$color-bg-label-dark-green-with-opacity: rgba(60, 164, 4, 0.05);
$color-bg-label-blue: #62afff;
$color-bg-label-blue-with-opacity: rgba(98, 175, 255, 0.05);
$color-bg-label-dark-blue: #0d5ba6;
$color-bg-label-dark-blue-with-opacity: rgba(13, 91, 166, 0.05);
$color-bg-label-purple: #9112ff;
$color-bg-label-purple-with-opacity: rgba(145, 18, 255, 0.05);
$color-bg-label-red: #f83f3d;
$color-bg-label-red-disabled: rgba(248, 63, 61, 0.3);
$color-bg-label-red-with-opacity: rgba(248, 63, 61, 0.05);
$color-bg-label-yellow: #fa7b4b;
$color-bg-label-yellow-with-opacity: rgba(250, 123, 75, 0.05);
$color-bg-label-yellow-light: #fca12f;
$color-bg-label-yellow-light-with-opacity: rgba(252, 161, 47, 0.05);
