.landing-wrapper {
  width: 100%;
  height: 100vh;

  .landing-container {
    max-width: 100%;
    height: calc(100vh - 163px);
    overflow-x: scroll;
  }
}
