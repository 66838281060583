@import "/src/assets/styles/colors";
@import "/src/assets/styles/fonts/fonts";

.table-pagination {
  min-width: 580px;
  max-width: 100%;
  align-self: center;
  justify-content: space-between;
}

th {
  background-color: $color-bg-white;
}

.table-pagination table {
  @include textSetting($color-text-input-value, 1.15rem, 500, 1.75rem);
  display: flex;
  justify-content: center;
  background-color: $color-bg-white;
  overflow: hidden;
}

.table-pagination td,
.table-pagination th {
  padding: 1rem 0.5rem 0.5rem;
}

.table-pagination th {
  background-color: $color-bg-white;
}

.table-pagination__item .MuiTablePagination-spacer {
  display: none;
}

.table-pagination__item .MuiTablePagination-toolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

.table-pagination__item .MuiTablePagination-selectLabel {
  margin: 0;
}

.table-pagination__item .MuiTablePagination-select {
  padding: 0.5rem 0.6rem;
  margin-right: 3rem;
  @include textSetting($color-text-input-value, 1rem, 500, 1.75rem);
  border: 1px solid $color-input-search-border;
  border-radius: 0.625rem;
  background-color: $color-bg-main;
  cursor: pointer;
}

.table-pagination__item .MuiTablePagination-select:hover {
  background-color: $color-input-search-border;
}

.table-pagination__item .MuiTablePagination-select:focus {
  outline: 1px solid $color-input-search-border;
}

.table-pagination__item .MuiTablePagination-displayedRows {
  margin: 0;

  @media (min-width: 768px) {
    margin-left: auto;
  }
}

.table-pagination__item .MuiTablePagination-actions {
  padding: 6px;
  border: 1px solid $color-input-search-border;
  border-radius: 0.625rem;
  background-color: $color-bg-main;
  text-align: center;
}

.table-pagination__item .MuiTablePagination-actions > button {
  margin: 0 8px;
  padding: 4px;
  border: transparent;
  border-radius: 2px;
  background-color: transparent;
}

.table-pagination__item .MuiTablePagination-actions > button:hover {
  background-color: $color-input-search-border;
  cursor: pointer;
}

.table-pagination__item .MuiTablePagination-actions > button:focus {
  outline: 1px solid $color-bg-grey-medium;
}

.table-pagination__item .MuiTablePagination-actions > button:disabled {
  opacity: 0.7;
  cursor: default;
}
