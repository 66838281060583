@use "../../../assets/styles/colors.scss" as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.25rem;
  border: 2px solid transparent;
  outline: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.313rem;
  color: $color-text-light;
  cursor: pointer;
  white-space: nowrap;

  &.with-icon {
    display: flex;
    justify-content: space-between;
  }

  &.white {
    background-color: $color-default-button-bg-white;
    color: $color-text-blue;
    border-color: $color-input-border;
  }

  &.blue {
    background-color: $color-default-button-bg;

    &:hover {
      background-color: $color-default-button-bg-active;
    }
  }

  &.light-blue {
    background-color: $color-default-button-bg-light-blue;
    color: $color-text-blue;

    &:hover {
      background-color: $color-default-button-bg-extra-light-blue;
    }
  }

  &.green {
    background-color: $color-label-active;
    color: $color-bg-label-dark-green;
  }

  &.fit-content {
    width: fit-content;
  }

  &.fixed {
    width: 7.5rem;
  }

  &.big {
    padding: 0.625rem 3.125rem;
    border-radius: 0.625rem;
    font-size: 1.5rem;
  }

  &.small {
    padding: 0.438rem 1.5rem;
    border-radius: 0.313rem;
  }

  &.disabled {
    pointer-events: none;
    cursor: auto;
    background-color: $color-default-button-bg-disabled;
    border-color: $color-default-button-bg-disabled;
    color: $color-text-light;
  }

  @media (max-width: 1800px) {
    font-size: 0.8rem;
    &.big {
      font-size: 1.3rem;
      padding: 0.625rem 2.125rem;
    }
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;

    &.small {
      padding: 0.4rem 1rem;
    }
    &.big {
      font-size: 1.3rem;
      padding: 0.625rem 2.125rem;
    }
  }
}
