@import "../../../assets/styles/colors.scss";

.join-us-user-pool {
  display: flex;
  padding-left: 9.375rem;
  padding-right: 3rem;
  padding-top: 7.25rem;
  margin-bottom: 6.25rem;
  background-color: $color-bg-white-darker;
  flex-direction: row;
  gap: 7.5rem;

  .button {
    width: 70%;
  }

  .join-us-section {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;

    &__header {
      font-size: 2.325rem;
      font-weight: 700;
      color: $color-text-input-value;
    }

    &__text {
      font-size: 1.225rem;
      font-weight: 300;
      color: $color-bg-grey-medium;
    }
  }

  .user-pool-section {
    display: flex;
    justify-content: center;
    .user-pool-section__image {
      width: 100%;
    }
  }

  @media (max-width: 1460px) {
    gap: 4px;
  }
}


@media (max-width: 1200px) {
  .join-us-user-pool {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    padding: 3rem;
    margin-bottom: 0;

    .join-us-section__header {
      text-align: center;
    }

    .join-us-section {
      align-items: center;
      .button {
        width: 70%;
      }
    }

    .join-us-section__text {
      text-align: center;
    }
  }

}

@media (max-width: 600px) {
  .join-us-user-pool{
    .join-us-section {
      .button {
        font-size: 1rem;
        padding: 0.2rem 1rem;
      }
    }
  }

}

@media (max-width: 460px) {
  .join-us-user-pool{
    .join-us-section {
      .button {
       width: 90%;
      }
    }
  }

}
