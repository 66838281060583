.chatbot-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;

  &:hover {
    box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
    -webkit-box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
    -moz-box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
  }
}

.chatbot-btn--chatbot {
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  z-index: 3;
}

.chatbot-container {
  position: absolute;
  bottom: 6rem;
  right: 3rem;

  .chatbot-btn--contact, .chatbot-btn--rate {
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0rem;
    right: 0rem;
    pointer-events: none;
    z-index: 1;
  }

  &.active {
    .chatbot-btn--contact, .chatbot-btn--rate {
      opacity: 1;
      pointer-events: all;
      box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
      -webkit-box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
      -moz-box-shadow: -1px 1px 25px -3px rgba(82,130,255,1);
    }

    .chatbot-btn--contact {
      top: -0.5rem;
      right: 6rem;
    }
  
    .chatbot-btn--rate {
      top: -5.8rem;
      right: 1rem;
    }
  }
}
