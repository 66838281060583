@import "../../assets/styles/colors.scss";

.search-header {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;

  .search {
    display: flex;
    height: 3.125rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 7.125rem;
  }

  .filters {
    margin-top: 1.25rem;

    .selected {
      display: inline-flex;
      padding: 0.3125rem 0.625rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border-radius: 0.3125rem;
      border: 1px solid $color-bg-blue-light;
      background: $color-bg-white-darkest;
      margin-right: 1.5rem;
      margin-bottom: 0.5rem;

      .icon-close {
        cursor: pointer;
      }

      & > p {
        color: $color-text-blue;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .search-container {
    display: flex;
    width: 100%;
    height: inherit;
    position: relative;
    align-items: center;

    border: 1px solid $color-input-search-border;
    border-radius: 0.312rem;
    background: $color-input-bg;

    &__reset-button {
      background-color: transparent;
      outline: transparent;
      border: none;
      cursor: pointer;
      margin: 0 1rem;
      height: fit-content;
      display: none;

      button {
        stroke: black;
      }
    }

    .icon-search {
      margin-left: 1.25rem;

      fill: red;
    }

    .container-input {
      border: none;
      width: 43%;
      padding: 1.5rem 1.25rem;
      outline: none;
      border-radius: 0.312rem;
      font-size: 1rem;
      background: transparent;
      flex: 1 1 0;

      &::placeholder {
        color: $color-input-placeholder;
      }
    }

    .line {
      display: flex;
      align-self: center;
      width: 1px;
      height: 2.1875rem;
      background: $color-input-placeholder;
    }

    .filter,
    .second-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      color: $color-text-grey;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      padding: 0 1rem;

      & > p {
        margin: 0 0.7rem;
      }

      & > .container-dropdown {
        .menu-button {
          margin-left: 0.625rem;
        }
      }
    }

    .container-button {
      min-width: 4.56rem;
      height: 100%;
      padding: 0.625rem 3.125rem;
      border-radius: 0.3125rem;
      box-shadow: 1px 1px 2px 0 rgba(82, 130, 255, 0.35);
      margin-right: 0;
      scale: 1.03;
    }
  }

  .search-button {
    padding: 0.625rem 3.125rem;
    height: inherit;
    border-radius: 0.3125rem;
    box-shadow: 1px 1px 2px 0 rgba(45, 96, 227, 0.65);
  }

  .menu-button {
    height: 100%;
  }
}

.reset-button {
  &.isResetButtonActive {
    display: flex;
  }
}
