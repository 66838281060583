@import "/src/assets/styles/colors.scss";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding: 6.25rem;
  padding: 12rem 9.375rem 5.625rem 9.375rem;

  .footer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3rem;

    &__image {
      width: 3.125rem;
    }
  }

  .footer-info {
    background-color: $color-bg-white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__contact {
      color: $color-text-grey-dark;

      &__header {
        font-size: 1.5rem;
        font-weight: 600;
        padding-bottom: 1rem;
      }

      &__text {
        font-size: 1.25rem;
        font-weight: 400;
        padding: 0.325rem 0;
      }
    }

    &__legal {
      text-align: end;
      color: $color-text-grey-dark;
      font-size: 1.25rem;
      font-weight: 400;
    }
  }

  @media (max-width: 1200px) {
    padding: 51px 112px;
  }

  @media (max-width: 700px) {
    padding:3rem;

    .footer-info {
      flex-direction: column;
      align-items: center;
      .footer-info__legal{
        margin-top: 1rem;
        text-align: start;
      }
    }
  }
}
