.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  padding: 3.1875rem 0;
  padding-left: 9.75rem;
  padding-right: 7rem;

  .landing-header-image {
    width: 3.125rem;
  }

  .landing-header-buttons {
    display: flex;
    flex-direction: row;
    gap: 1.1875rem;
  }

  @media (max-width: 1200px) {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  @media (max-width: 700px) {
    padding: 3rem;

    .landing-header-buttons{
      gap: 0.5rem;
    }
  }

  @media (max-width: 460px) {
    padding: 1.5rem;
  }
}
