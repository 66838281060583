@use '../../../../assets/styles/colors.scss' as *;

.triangle-cell {
  border-top-left-radius: 4px;

  &__text {
    position: absolute;
    color: $color-text-blue;

    &--policy{
      top: 0;
      left: 9.5rem;
    }

    &--user {
      top: 1.4rem;
      left: -2.4rem;
    }
  }
  
  &__policy {
    position: relative;
    border: 55px solid transparent; 
    border-top:15px solid $color-input-bg; 
    border-right: 220px solid $color-input-bg; // гипотенуза
  }
}