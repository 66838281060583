@import "../../assets/styles/colors.scss";

.sign-up-page {
  display: flex;
  align-items: start;
  justify-content: space-between;

  .button {
    width: 100%;
  }
}
