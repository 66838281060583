@import "/src/assets/styles/colors.scss";

.trigger-button {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__icon {
    border: solid $color-bg-blue-main;
    background-color: transparent;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.dropdown {
  background-color: $color-bg-white;
  border-radius: 0.625rem;
  padding: 1.25rem;
  inset: 35px auto auto -85px !important;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  & > ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0.8125rem;
  }

  .dropdown-item {
    display: flex;
    cursor: pointer;
    width: 14rem;
    gap: 0.625rem;

    &__text {
      font-size: 1.25rem;

      &__link {
        text-decoration: none;
        color: black;
      }
    }
    // &:hover {
    //   color: $color-text-blue;
    // }
  }
}
