@use '../../../assets/styles/colors.scss' as * ;

.input-wrapper{
    display: flex;
    flex-direction: column;

    .input{
        padding: 1.5rem 1.25rem;
        margin-bottom: 0.313rem;
        outline: none;
        border-radius: 0.625rem;
        border: 1px solid $color-input-border;
        font-size: 1rem;
        background: $color-input-bg;
        &::placeholder {
            color: $color-input-placeholder;
        }


        &-label{
            color: $color-input-border;
            margin-bottom: 0.625rem;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .error-msg{
        color: $color-input-error;
        font-size: 0.9rem;
        min-height: 1rem;
    }

    &.error{
        .input{
            border-color: $color-input-error;
            &-label{
                color: $color-input-error; 
            }
        }
    }
}
