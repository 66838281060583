@import "/src/assets/styles/colors.scss";
@import "/src/assets/styles/fonts/fonts";

.modal-policy {
  display: flex;
  flex-direction: column;

  &__with-input {
    gap: 1.25rem;

    &_type_swagger-modal {
      gap: 0.5rem;
    }
  }

  &__content {
    padding-bottom: 1.25rem;
    gap: 1.875rem;

    &_type_group {
      gap: 0.5rem;
      max-height: 80vh;

      .modalInput_type_file {
        margin-top: 1rem;
      }
    }
  }

  &__loading {
    text-align: center;
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &_type_http {
      gap: 1.5625rem;
    }
  }

  &__header-container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    white-space: nowrap;
    overflow: hidden;
    min-height: 6rem;
  }

  button {
    align-self: end;
  }

  &__modal-title {
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
  }

  &__waiting-title {
    @include textSetting($color-bg-black, 1.5rem, 600, 1.75rem);
    //width: 42.5rem;
    text-align: center;
    height: 3.125rem;
  }

  &__modal-second-title {
    @include textSetting($color-text-grey-dark, 1rem, 300, 1.7653rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__modal-subtitle {
    @include textSetting($color-bg-black, 1rem, 300, 1.7653rem);
  }

  &__modal-button-container {
    display: flex;
    gap: 1.25rem;
    justify-content: end;
    width: fit-content;
    align-self: end;

    &_type_swagger-modal {
      padding-top: 1rem;
    }

    button {
      align-self: end !important;
    }
  }

  &__checkboxes-container {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;
  }

  &__label {
    display: flex;
    gap: 1rem;
    width: 13.375rem;
    align-items: center;
    @include textSetting($color-bg-black, 1.25rem, 500, 1.75rem);

    &_type_group {
      width: 100%;
    }

    input[type="radio"],
    input[type="checkbox"] {
      display: none;
    }

    input[type="radio"] + .modal-policy__radiomark,
    input[type="checkbox"] + .modal-policy__radiomark {
      background-image: url("../../../assets/media/icons/radio.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      width: 1.25rem;
      height: 1.25rem;
    }

    input[type="radio"]:checked + .modal-policy__radiomark,
    input[type="checkbox"]:checked + .modal-policy__radiomark {
      background-image: url("../../../assets/media/icons/radio-active.svg");
    }
  }

  &__config-table-container {
    height: 60vh;
    overflow: hidden;
  }

  &__config-child-container {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.8rem;
    justify-content: start;
  }

  &__config-field {
    display: flex;
    border: 1px solid transparent;
    border-radius: 0.3125rem;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0.125rem;
    }
  }

  &__config-title-container {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }

  &__config-method {
    min-width: 6.25rem;
    height: 2.125rem;
    @include textSetting($color-text-dark-light, 1rem, 600, 1.75rem);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    margin: 0.1875rem;
  }

  &__config-title {
    @include textSetting($color-text-black-light, 1rem, 500, 1.75rem);
  }

  &__config-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__config-label {
    height: 2.125rem;
    width: 4.6875rem;

    &_type_false {
      margin-right: 0.1875rem;
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"] + .modal-policy__config-radiomark {
      @include textSetting($color-text-light, 1rem, 600, 1.75rem);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-bg-label-purple;

      &:hover {
        cursor: pointer;
      }

      &_type_true {
        background-color: $color-bg-label-dark-green-disabled;
        border-radius: 0.3125rem 0 0 0.3125rem;
      }

      &_type_false {
        background-color: $color-bg-label-red-disabled;
        border-radius: 0 0.3125rem 0.3125rem 0;
      }
    }

    .modal-policy__config_input_false[type="radio"]:checked
      + .modal-policy__config-radiomark_type_false {
      background-color: $color-bg-label-red;
      cursor: default;
    }

    .modal-policy__config_input_true[type="radio"]:checked
      + .modal-policy__config-radiomark_type_true {
      background-color: $color-bg-label-dark-green;
      cursor: default;
    }
  }

  &__group-title-container {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 2rem;
  }

  &__table-title {
    @include textSetting($color-bg-grey-main, 1.25rem, 500, 2rem);
  }

  &__field-container {
    overflow-y: scroll;
    padding: 0.65rem 0.35rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__group-item-container {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid $color-bg-grey-light;
    align-items: center;
  }

  &__policy-type {
    @include textSetting($color-text-blue, 1.25rem, 600, 1.75rem);
  }
}

.config-container-height {
  &.small {
    height: 25vh;
  }

  &.middle {
    height: 40vh;
  }

  &.big {
    height: 60vh;
  }
}

.gap-minimize {
  gap: 0.8125rem;
}

.padding-container {
  &.wide {
    padding: 0 1.25rem 1.25rem;
  }

  &.narrow {
    padding: 0.625rem 1.25rem;
  }
}

.config-container-color {
  &.get {
    border-color: $color-bg-label-blue;
    background-color: $color-bg-label-blue-with-opacity;
  }

  &.head {
    border-color: $color-bg-label-purple;
    background-color: $color-bg-label-purple-with-opacity;
  }

  &.post {
    border-color: $color-bg-label-yellow;
    background-color: $color-bg-label-yellow-with-opacity;
  }

  &.put {
    border-color: $color-bg-label-green;
    background-color: $color-bg-label-green-with-opacity;
  }

  &.delete {
    border-color: $color-bg-label-red;
    background-color: $color-bg-label-red-with-opacity;
  }

  &.connect {
    border-color: $color-bg-label-dark-green;
    background-color: $color-bg-label-dark-green-with-opacity;
  }

  &.options {
    border-color: $color-bg-label-dark-blue;
    background-color: $color-bg-label-dark-blue-with-opacity;
  }

  &.trace {
    border-color: $color-bg-label-yellow-light;
    background-color: $color-bg-label-yellow-light-with-opacity;
  }

  &.patch {
    border-color: $color-bg-label-green-light;
    background-color: $color-bg-label-green-light-with-opacity;
  }
}

.config-method-color {
  &.get {
    background-color: $color-bg-label-blue;
  }

  &.head {
    background-color: $color-bg-label-purple;
  }

  &.post {
    background-color: $color-bg-label-yellow;
  }

  &.put {
    background-color: $color-bg-label-green;
  }

  &.delete {
    background-color: $color-bg-label-red;
  }

  &.connect {
    background-color: $color-bg-label-dark-green;
  }

  &.options {
    background-color: $color-bg-label-dark-blue;
  }

  &.trace {
    background-color: $color-bg-label-yellow-light;
  }

  &.patch {
    background-color: $color-bg-label-green-light;
  }
}
