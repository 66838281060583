@import "../../../assets/styles/colors.scss";

.input-search {
  display: flex;

  .input {
    padding: 1.5rem 1.25rem;
    outline: none;
    flex-shrink: 0;
    border-radius: 0.312rem;
    border: 1px solid $color-input-search-border;
    font-size: 1rem;
    background: $color-input-bg;
    &::placeholder {
      color: $color-input-placeholder;
    }
  }
}
